import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import WebDivisionLogo from '../components/WebDivisionLogo';
import SEO from '../components/seo';

const Box = styled.div`
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.4) 0%, rgb(0, 0, 0) 100%);
    height: 100vh;
    padding: 30px;
    display: grid;
    align-content: center;
`;

const IndexPage = () => (
    <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Box>
            <WebDivisionLogo />
        </Box>
    </Layout>
);

export default IndexPage;
