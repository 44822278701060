import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
`;

const Letters = styled.svg`
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.8));
`;

const VShape = styled.svg`
    position: absolute;
    left: 0;
    filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.8));
    & g > * {
        fill: #ccff00;
    }
`;

const StopColor1 = styled.stop`
    stop-color: rgba(255, 255, 255, 0.9);
`;

const StopColor2 = styled.stop`
    stop-color: rgba(255, 255, 255, 0.9);
    stop-opacity: 0.8;
`;

const Path = styled.path`
    fill: #ccff00;
`;

const WebDivisionLogo = () => (
    <LogoContainer
        version="1.1"
        id="Logo"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1290 556"
        enableBackground="new 0 0 1290 556"
        xmlSpace="preserve"
    >
        <Letters
            version="1.1"
            id="Log2o"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1290 556"
            enableBackground="new 0 0 1290 556"
            xmlSpace="preserve"
        >
            <defs>
                <linearGradient id="Gradient" x1="0" x2="0" y1="0" y2="1">
                    <StopColor1 offset="0%" />
                    <StopColor2 offset="50%" />
                    <StopColor1 offset="100%" />
                </linearGradient>
            </defs>
            <g id="WEB" fill="url(#Gradient)">
                <path
                    id="W"
                    d="M69.9,223.8l-32.5-66.6H26.5l36.4,74.3h13.2l29.4-65l29.4,65h12.4l32.9-74.3h-9.7l-29.4,66.7l-30.2-66.7h-10.8		L69.9,223.8z"
                />
                <path id="E" d="M266,231.6v-7.7h-65v-26.3h61.9v-7.7H201V165h65v-7.7h-74.3v74.3H266z" />
                <path
                    id="B"
                    d="M336.9,231.6c4,0,8-0.2,12-0.6c2.9-0.3,5.7-1,8.5-1.9c2-0.7,3.9-1.8,5.5-3.2c1.4-1.3,2.5-2.8,3.2-4.5		c0.8-1.8,1.3-3.8,1.5-5.8c0.3-2.4,0.4-4.7,0.4-7.1c0-1.9-0.2-3.8-0.7-5.6c-0.4-1.5-1-2.9-1.8-4.3c-0.7-1.1-1.5-2.2-2.5-3		c-0.9-0.8-1.8-1.5-2.8-2.1c-2.4-1.3-5-2-7.7-2.1c2.2-0.1,4.3-0.8,6.2-2.1c0.8-0.6,1.6-1.2,2.3-2c0.8-0.9,1.5-1.9,2-2.9		c0.6-1.3,1.1-2.7,1.4-4.1c0.4-1.8,0.6-3.6,0.5-5.5c0-2.5-0.2-5.1-0.8-7.5c-0.5-2.1-1.5-4-3.1-5.5c-1.9-1.6-4.1-2.8-6.6-3.3		c-3.7-0.8-7.4-1.2-11.2-1.1h-62v74.3H336.9L336.9,231.6z M290.5,223.8V196h43.3c3.6,0,6.7,0.1,9.4,0.2c2.3,0.1,4.5,0.3,6.7,0.7		c1.5,0.2,3,0.7,4.4,1.3c1.1,0.5,2,1.3,2.6,2.2c0.7,1,1.1,2.2,1.3,3.4c0.2,1.6,0.4,3.1,0.3,4.7c0,2-0.1,4-0.3,6		c-0.2,1.5-0.6,2.9-1.3,4.2c-0.6,1.1-1.5,2.1-2.6,2.8c-1.4,0.8-2.9,1.3-4.4,1.6c-2.2,0.4-4.5,0.7-6.7,0.7c-2.6,0.1-5.8,0.2-9.4,0.2		L290.5,223.8L290.5,223.8z M290.5,188.2V165h49.6c2.5,0,5.1,0.1,7.6,0.4c1.7,0.1,3.3,0.6,4.7,1.4c1.1,0.7,2,1.7,2.4,3		c0.5,1.6,0.7,3.3,0.7,5c0,1.7-0.1,3.3-0.3,5c-0.1,1.3-0.5,2.5-1.1,3.6c-0.6,1-1.4,1.8-2.3,2.4c-1.2,0.7-2.5,1.2-3.9,1.5		c-1.9,0.4-3.9,0.6-5.9,0.7c-2.3,0.1-5,0.2-8.2,0.2L290.5,188.2L290.5,188.2z"
                />
            </g>
            <g id="DIISION" fill="url(#Gradient)">
                <path
                    id="N"
                    d="M1102.8,271.2l136.7,126.3h23.5v-141h-17.6v123.4l-129.2-123.4h-30.9v141h17.6L1102.8,271.2L1102.8,271.2z"
                />
                <path
                    id="O_1_"
                    d="M949.7,256.3c-8.8-0.1-17.5,0.5-26.2,1.9c-6.1,0.9-12,2.9-17.5,5.7c-4.3,2.3-8,5.6-10.6,9.7		c-2.6,4.2-4.5,8.8-5.5,13.7c-1.2,5.8-1.9,11.8-2,17.8c-0.2,6.6-0.3,13.9-0.3,21.9s0.1,15.3,0.3,21.9c0.1,6,0.8,11.9,2,17.8		c1,4.9,2.8,9.5,5.5,13.7c2.7,4.1,6.3,7.4,10.6,9.7c5.5,2.9,11.4,4.8,17.5,5.7c8.7,1.4,17.4,2,26.2,1.9h51		c8.8,0.1,17.5-0.5,26.2-1.9c6.1-0.9,12-2.9,17.5-5.7c4.3-2.3,7.9-5.6,10.6-9.7c2.6-4.2,4.4-8.8,5.4-13.7c1.2-5.8,1.9-11.8,2-17.8		c0.2-6.6,0.3-13.9,0.3-21.9c0-8-0.1-15.3-0.3-21.9c-0.1-6-0.8-11.9-2-17.8c-1-4.8-2.8-9.5-5.4-13.7c-2.7-4.1-6.3-7.4-10.6-9.7		c-5.5-2.9-11.4-4.8-17.5-5.7c-8.7-1.4-17.4-2-26.2-1.9L949.7,256.3L949.7,256.3z M949.7,383.5c-7.5,0-13.9-0.2-19-0.7		c-4.4-0.3-8.7-1.2-12.8-2.7c-3.1-1.1-5.8-3.2-7.7-5.8c-2-3-3.3-6.5-3.9-10.1c-0.9-5.1-1.4-10.2-1.5-15.4		c-0.1-6.1-0.2-13.4-0.2-21.9c0-8.5,0.1-15.8,0.2-21.9c0-5.2,0.5-10.3,1.4-15.4c0.6-3.6,1.9-7,4-10.1c2-2.6,4.7-4.7,7.8-5.8		c4.1-1.5,8.4-2.4,12.8-2.7c5.2-0.4,11.5-0.7,19-0.7h51c7.5,0,13.9,0.2,19,0.7c4.3,0.3,8.6,1.2,12.7,2.7c3.1,1.2,5.8,3.2,7.7,5.8		c2,3,3.4,6.5,4,10.1c0.9,5.1,1.4,10.2,1.5,15.4c0.1,6.1,0.2,13.4,0.2,21.9c0,8.5-0.1,15.8-0.2,21.9c-0.1,5.2-0.5,10.3-1.5,15.4		c-0.6,3.6-1.9,7-4,10.1c-2,2.6-4.7,4.7-7.8,5.8c-4.1,1.5-8.4,2.4-12.8,2.7c-5.2,0.4-11.5,0.7-18.9,0.7H949.7z"
                />
                <path
                    id="S"
                    d="M736,318.4c-7.5,0-14-0.2-19.4-0.5c-4.6-0.2-9.2-0.8-13.8-1.6c-3.1-0.6-6.2-1.5-9.1-2.9c-2.1-1-3.9-2.5-5.3-4.4		c-1.3-1.8-2.2-3.9-2.5-6c-0.4-2.6-0.6-5.2-0.6-7.8c-0.1-4.1,0.7-8.2,2.5-11.9c2-3.5,5.1-6.1,8.8-7.6c5.5-2.2,11.4-3.5,17.3-4		c9.3-0.9,18.6-1.3,27.9-1.2h16.9c8.5,0,15.6,0.2,21.3,0.6c4.8,0.2,9.5,1.1,13.9,2.8c3.3,1.3,6,3.6,7.6,6.7		c1.7,3.9,2.5,8.2,2.3,12.4h16.9c0-3.9-0.3-7.7-1.1-11.5c-0.6-3.3-1.9-6.5-3.7-9.3c-1.9-2.8-4.4-5.2-7.2-7		c-3.5-2.2-7.4-3.9-11.4-4.9c-5.4-1.5-10.9-2.4-16.4-2.9c-6.3-0.6-13.8-0.9-22.2-0.9h-16.9c-10.3,0-19.3,0.4-27,1.1		c-6.6,0.6-13.2,1.7-19.6,3.4c-4.7,1.2-9.2,3.1-13.4,5.6c-3.2,2-6,4.6-8.2,7.6c-2,2.9-3.4,6.1-4.1,9.6c-0.8,3.8-1.1,7.6-1.1,11.4		c0,3.7,0.2,7.3,0.7,11c0.4,3.2,1.5,6.4,3,9.2c1.7,2.9,4,5.4,6.8,7.4c3.7,2.4,7.7,4.3,12,5.4c6.1,1.6,12.3,2.7,18.6,3.3		c8.8,0.8,17.7,1.2,26.6,1.1h23.3c7.6-0.1,15.3,0.3,22.8,1.2c4.7,0.5,9.3,1.8,13.5,4c3,1.7,5.3,4.4,6.4,7.6c1.2,3.9,1.7,7.9,1.6,12		c0.1,4.2-0.7,8.4-2.4,12.2c-1.8,3.5-4.7,6.4-8.2,8.1c-4.9,2.4-10.2,3.9-15.6,4.5c-8.2,1-16.4,1.5-24.6,1.4h-22.6		c-8.5,0-15.6-0.2-21.3-0.6c-4.8-0.2-9.4-1.1-13.9-2.9c-3.3-1.3-6-3.7-7.6-6.8c-1.7-4-2.5-8.3-2.3-12.6h-16.9		c0,3.9,0.3,7.8,1.1,11.6c0.6,3.3,1.9,6.5,3.7,9.3c1.9,2.8,4.3,5.3,7.2,7.1c3.5,2.3,7.3,3.9,11.4,5c5.4,1.5,10.9,2.4,16.4,2.9		c6.3,0.6,13.8,1,22.2,1h22.6c8.2,0.1,16.5-0.4,24.6-1.3c6.1-0.7,12.2-1.9,18-3.7c4.4-1.3,8.6-3.4,12.3-6c3-2.1,5.6-4.9,7.7-8		c1.9-3,3.2-6.4,3.9-9.9c0.8-3.8,1.1-7.7,1.1-11.5c0-3.8-0.2-7.7-0.7-11.5c-0.4-3.3-1.4-6.6-2.9-9.6c-1.5-2.9-3.7-5.5-6.3-7.6		c-3.3-2.5-6.9-4.4-10.9-5.6c-5.5-1.7-11.1-2.8-16.8-3.4c-7.9-0.8-15.9-1.2-23.9-1.2L736,318.4L736,318.4z"
                />
                <Path id="I_2_" d="M397.5,397.6h17V256.3h-17V397.6L397.5,397.6z" />
                <Path
                    id="O"
                    d="M193.5,397.7l113.2,0c8.8,0.1,17.5-0.5,26.2-1.9c6.1-0.9,12-2.9,17.5-5.7c4.3-2.3,7.9-5.6,10.6-9.7		c2.6-4.2,4.4-8.8,5.4-13.7c1.2-5.8,1.9-11.8,2-17.8c0.2-6.6,0.3-13.9,0.3-21.9c0-8-0.1-15.3-0.3-21.9c-0.1-6-0.8-11.9-2-17.8		c-1-4.8-2.8-9.5-5.4-13.7c-2.7-4.1-6.3-7.4-10.6-9.7c-5.5-2.9-11.4-4.8-17.5-5.7c-8.7-1.4-130.6-2-139.4-1.9 M193.5,256.3		 M210.5,270.5c7.5,0,110.1,0.2,115.3,0.7c4.3,0.3,8.6,1.2,12.7,2.7c3.1,1.2,5.8,3.2,7.7,5.8c2,3,3.4,6.5,4,10.1		c0.9,5.1,1.4,10.2,1.5,15.4c0.1,6.1,0.2,13.4,0.2,21.9c0,8.5-0.1,15.8-0.2,21.9c-0.1,5.2-0.5,10.3-1.5,15.4c-0.6,3.6-1.9,7-4,10.1		c-2,2.6-4.7,4.7-7.8,5.8c-4.1,1.5-8.4,2.4-12.8,2.7c-5.2,0.4-107.7,0.7-115.2,0.7"
                />
                <path id="I_1_" d="M625.5,397.6h17V256.4h-17V397.6L625.5,397.6z" />
                <path id="I_3_" d="M847.1,397.6h17V256.3h-17V397.6L847.1,397.6z" />
            </g>
        </Letters>
        <VShape
            version="1.1"
            id="VshapeLogo"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1290 556"
            enableBackground="new 0 0 1290 556"
            xmlSpace="preserve"
        >
            <g id="Vshape">
                <path
                    id="Stroke_top"
                    fill="#ff6600"
                    stroke="#000000"
                    strokeMiterlimit="100"
                    d="M362,79.2c0,0,90.5,187.6,384,81.9		c12.5-4.5-7.3-23.7-7.3-23.7s-248.2,102.4-364-83.7C370.5,46.8,362,79.2,362,79.2z"
                />
                <path
                    id="Stroke_left"
                    fill="#ff6600"
                    stroke="#000000"
                    strokeMiterlimit="100"
                    d="M438.3,475.3c0,0,57.9-226-74.6-391.3		c-4.8-6,20-9.1,20-9.1s126.5,75.9,87.4,385.8C459,483,438.3,475.3,438.3,475.3z"
                />
                <path
                    id="Stroke_right"
                    fill="#ff6600"
                    stroke="#000000"
                    strokeMiterlimit="100"
                    d="M744.3,167c0,0-216.7,64.6-274.3,297.3		c-0.7,2.5-30-7.5-30-7.5s61-256.3,297-317.2"
                />
                <circle
                    id="Dot_2_"
                    fill="#ff6600"
                    stroke="#000000"
                    strokeMiterlimit="100"
                    cx="756"
                    cy="149.3"
                    r="31.8"
                />
                <circle
                    id="Dot_1_"
                    fill="#ff6600"
                    stroke="#000000"
                    strokeMiterlimit="100"
                    cx="451"
                    cy="477.3"
                    r="31.8"
                />
                <circle id="Dot" fill="#ff6600" stroke="#000000" strokeMiterlimit="100" cx="368" cy="69" r="31.8" />
            </g>
        </VShape>
    </LogoContainer>
);

export default WebDivisionLogo;
